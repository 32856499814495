<template>
  <div ref="videoViewRef" id="videoView" :class="$style.wrapVideo">
    <div :id="id"></div>
    <div
      v-if="showPantile"
      :class="[$style.ezPtzWrap]"
      id="ez-ptz-item"
      style="display: block;"
    >
      <div
        :class="[
          $style.ezPtzContainer,
          cameraDirection !== 4
            ? $style['ezPtzContainer' + cameraDirection]
            : '',
        ]"
        id="ez-ptz-container"
        ref="ptzContainer"
        @mousedown="
          e => {
            setDirection(e, 'start');
          }
        "
        @mouseup="
          e => {
            setDirection(e, 'stop');
          }
        "
      >
        <div :class="[$style.ezPtzMain, $style.center]"></div>
        <div :class="[$style.ezPtzIcon, $style.top, $style.active]"></div>
        <div :class="[$style.ezPtzIcon, $style.left, $style.active]"></div>
        <div :class="[$style.ezPtzIcon, $style.bottom, $style.active]"></div>
        <div :class="[$style.ezPtzIcon, $style.right, $style.active]"></div>
      </div>
    </div>
    <div :class="[$style.wrapPlayer]" v-if="showOperation">
      <span :class="$style.operation">
        <!-- 播放/停止 -->
        <span :class="$style.iconBtn" @click="handleAutoAction">
          <ali-icon
            type="tc-icon-zanting"
            v-if="autoAction"
            :style="iconStyleObject"
            title="暂停"
          />
          <ali-icon
            type="tc-icon-bofang"
            v-else
            :style="iconStyleObject"
            title="播放"
          />
        </span>
        <!-- 开启/静音 -->
        <span :class="$style.iconBtn" @click="handleSoundChange">
          <ali-icon
            type="tc-icon-jingyin"
            v-if="!sound"
            title="静音"
            :style="iconStyleObject"
          />
          <ali-icon
            type="tc-icon-jiayinliang"
            v-else
            title="声音"
            :style="iconStyleObject"
          />
        </span>
      </span>
      <span :class="$style.operation">
        <!-- 方向控制 -->
        <span
          id="pantile"
          :class="$style.iconBtn"
          v-if="isPantile"
          title="控制云台"
          @click="handlePantile"
        >
          <ali-icon type="tc-icon-kongzhi" :style="iconStyleObject" />
        </span>
        <!-- 标清（SD）和高清（HD） -->
        <span :class="$style.iconBtn" @click="handleActionHDORSD">
          <ali-icon
            type="tc-icon-gaoqing"
            v-if="!boolIsSD"
            :style="iconStyleObject"
            title="高清"
          />
          <ali-icon
            type="tc-icon-biaoqing1"
            v-else
            :style="iconStyleObject"
            title="标清"
          />
        </span>
        <!-- 截图 -->
        <span :class="$style.iconBtn" @click="handleCapturePicture">
          <ali-icon
            type="tc-icon-paizhao"
            :style="iconStyleObject"
            title="截图"
          />
        </span>
        <!-- 录屏 -->
        <span :class="$style.iconBtn" @click="handleActionSaveChange">
          <ali-icon
            type="tc-icon-a-shexiangjiluxiangji"
            title="录屏"
            :style="{
              fontSize: iconSize,
              color: actionSave ? 'rgb(255, 0, 0)' : 'rgb(255, 255, 255)',
              padding: '0 10px',
            }"
          />
        </span>
        <!-- 回放 -->
        <template v-if="enableBack">
          <span
            :class="$style.iconBtn"
            @click="handleModeChange"
            v-if="!isRecMode"
          >
            <ali-icon
              type="tc-icon-iconfonttubiao_shipinhuifang"
              title="回放"
              :style="iconStyleObject"
            />
          </span>
          <span :class="$style.iconBtn" @click="handleModeChange" v-else>
            <ali-icon type="tc-icon-signal" :style="iconStyleObject" />
          </span>
        </template>
        <!-- 全屏 -->
        <span :class="$style.iconBtn" @click="handleFullScreen()">
          <ali-icon
            type="tc-icon-a-quanping1"
            :style="iconStyleObject"
            title="全屏"
          />
        </span>
      </span>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import EZUIKit from 'ezuikit-js';
import { delay } from '@triascloud/utils';
import AliIcon from '@/components/ali-icon';
import { throttle } from 'lodash';
import screenfull from '@/utils/fullscreen';

@Component({
  components: {
    AliIcon,
  },
})
export default class videoView extends Vue {
  @Prop({
    required: true,
    type: String,
    default: 'ra.29udz5tf81wjt5fq1whj9dv920duzvj4-3d48eec7wm-0rbkt6a-eja68btal',
  })
  accessToken;
  @Prop({
    required: true,
    type: String,
    default: 'ezopen://open.ys7.com/G39444019/1.hd.live',
  })
  url;
  @Prop({ type: Boolean, default: true }) enableBack;
  @Prop({ type: Boolean, default: true }) showOperation;
  @Prop({ type: String, default: '0.28rem' }) iconSize;

  player = null;
  iconStyleObject = {
    fontSize: '0.16rem',
    color: 'rgb(255, 255, 255)',
    padding: '0 10px',
  };
  width = 0;
  height = 0;
  id = `video${parseInt(Math.random() * 1000)}${Date.now()}`;
  async mounted() {
    this.iconStyleObject.fontSize = this.iconSize;
    await this.calcRectFn();
    await delay(50);
    this.createVideo();
    this.resize();
  }
  beforeDestroy() {
    if (this.player) {
      this.player.stop();
      // this.player.destroy();
    }
    this.player = null;
  }
  resize() {
    document.addEventListener('fullscreenchange', async () => {
      if (!screenfull.isFullscreen && this.fullScreenStatus === 1) {
        this.fullScreenStatus = 0;
        this.handleResize(0, 0);
        await this.$nextTick();
        await delay(200);
        await this.calcRectFn();
      }
    });
    window.addEventListener(
      'resize',
      throttle(async () => {
        // 全屏阻止接下来的操作
        if (this.fullScreenStatus === 1) return false;
        if (this.fullScreenChange) return false;
        this.handleResize(0, 0);
        await this.$nextTick();
        await delay(200);
        await this.calcRectFn();
      }, 500),
    );
  }
  async calcRectFn() {
    // 播放器继承外层容器大小
    const dom = this.$refs.videoViewRef.parentNode;
    const rect = dom.getBoundingClientRect();
    this.width = rect.width;
    this.height = rect.height;
    await delay(100);
    this.handleResize(this.width, this.height);
  }
  handleResize(width = 0, height = 0) {
    try {
      this.player && this.player.reSize && this.player.reSize(width, height);
    } catch (error) {
      // eslint-disable-next-line no-console
      return false;
    }
  }
  async createVideo() {
    await this.$nextTick();
    if (this.player) {
      this.player.stop();
      this.player = null;
    }
    const params = {
      id: this.id,
      accessToken: this.accessToken,
      url: this.url.replace('.hd.live', this.isRecMode ? '.rec' : '.live'),
      template: (this.isRecMode && 'pcRec') || 'simple',
      handleSuccess: () => {},
      handleError: () => {},
      width: this.width,
      height: this.height,
      bSupporDoubleClickFull: false,
      clickEventHandle: false,
      audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
      ...this.option,
    };
    this.player = new EZUIKit.EZUIKitPlayer(params);
    this.showPantile = false;
    this.isPantile = false;
    await delay(1000);
    this.isPantile =
      !this.isRecMode &&
      this.player?.capacity?.ptz_left_right === '1' &&
      this.player?.capacity?.ptz_top_bottom === '1';
  }
  /** @name 回放 */
  isRecMode = false;
  handleModeChange() {
    // this.isRecMode = !this.isRecMode;
    // this.createVideo();
    this.autoAction = false;
    this.handleStopPlay();
    const url = this.url.replace('.hd.live', '.rec');
    window.open(
      `https://open.ys7.com/console/jssdk/pc.html?url=${url}&accessToken=${this.accessToken}&themeId=pcRec&env=`,
      '_blank',
    );
  }
  /** @name 标清链接 */
  get SDURL() {
    return this.url ? this.url.replace('.hd.live', '.live') : this.url;
  }
  /** @name 高清链接 */
  get HDURL() {
    return this.url;
  }
  /** @name 声音控制 */
  sound = false;
  handleSoundChange() {
    if (this.sound) {
      this.sound = false;
      this.handleCloseSound();
    } else {
      this.sound = true;
      this.handleOpenSound();
    }
  }
  /** @name 播放/暂停 */
  autoAction = true;
  handleAutoAction() {
    if (this.autoAction) {
      this.autoAction = false;
      this.handleStopPlay();
    } else {
      this.autoAction = true;
      this.handleStartPlay();
    }
  }
  /** @name 摄像头方向控制 */
  showPantile = false;
  isPantile = false;
  handlePantile() {
    this.showPantile = !this.showPantile;
  }
  cameraDirection = 4;
  async setDirection(e, optType) {
    const container = this.$refs.ptzContainer.getBoundingClientRect();
    const containerCenterX = container.left + 41;
    const containerCenterY = container.top + 41;
    const eventX = e.x || e.changedTouches[0].clientX;
    const eventY = e.y || e.changedTouches[0].clientY;
    const left = eventX - containerCenterX;
    const top = eventY - containerCenterY;
    let direction = 0;

    if (Math.abs(left) > Math.abs(top)) {
      if (left > 0) {
        direction = 3;
      } else {
        direction = 2;
      }
    } else {
      if (top > 0) {
        direction = 1;
      } else {
        direction = 0;
      }
    }
    this.cameraDirection = optType === 'start' ? direction : 4;
    const url = this.player.env.domain + '/api/lapp/device/ptz/' + optType;
    const data = new FormData();
    data.append(
      'deviceSerial',
      this.matchEzopenUrl(this.player.url).deviceSerial,
    );
    data.append('channelNo', this.matchEzopenUrl(this.player.url).channelNo);
    data.append('speed', 1);
    data.append('direction', direction);
    data.append('accessToken', this.accessToken);
    await this.sendRequest(url, data);
  }
  sendRequest(url, data) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'POST',
        body: data,
      })
        .then(response => response.json())
        .then(rt => {
          resolve(rt);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  matchEzopenUrl(ezopenUrl) {
    var deviceSerial = ezopenUrl.split('/')[3];
    var channelNo = ezopenUrl.split('/')[4].split('.')[0];
    var validCode =
      ezopenUrl.split('/')[2].split('@').length === 2
        ? ezopenUrl.split('/')[2].split('@')[0]
        : '';
    var hd = ezopenUrl.indexOf('.hd') !== -1;
    var type = ezopenUrl
      .split('/')[4]
      .split('.')
      [ezopenUrl.split('/')[4].split('.').length - 1].split('?')[0];

    if (type === 'rec' && ezopenUrl.indexOf('.cloud.rec') !== -1) {
      type = 'cloud.rec';
    }

    return {
      deviceSerial,
      channelNo,
      validCode,
      hd,
      type,
    };
  }
  /** @name 清晰度切换 */
  boolIsSD = true;
  link = this.SDURL;
  handleActionHDORSD() {
    if (this.boolIsSD) {
      this.handleOpenHD();
    } else {
      this.handleOpenSD();
    }
    this.player.changePlayUrl({
      url: this.link,
    });
  }
  changeVideoLink(link) {
    this.player.changePlayUrl({
      url: link,
    });
  }
  /** @name 录屏 */
  actionSave = false;
  handleActionSaveChange() {
    if (this.actionSave) {
      this.actionSave = false;
      this.handleStopSave();
    } else {
      this.actionSave = true;
      this.handleStartSave();
    }
  }
  /** @name 视频的功能操作方法 */
  fullScreenStatus = 0;
  fullScreenChange = false;
  handleFullScreen() {
    const id = document.getElementById(this.id);
    this.fullScreenChange = true;
    if (screenfull.element === id && screenfull.isFullscreen) {
      screenfull.exit();
      this.player.cancelFullScreen();
      this.fullScreenStatus = 0;
      this.fullScreenChange = false;
    } else {
      screenfull.request(id);
      setTimeout(() => {
        const wraper = document.getElementById(`${this.id}`);
        wraper.style.width = '100%';
        wraper.style.height = '100%';

        this.fullScreenChange = false;
        this.player.fullScreen();
        this.fullScreenStatus = 1;
      }, 50);
    }
  }
  handleOpenSD() {
    this.boolIsSD = true;
    this.link = this.SDURL;
  }
  handleOpenHD() {
    this.boolIsSD = false;
    this.link = this.HDURL;
  }
  handleCloseSound() {
    this.player.closeSound();
  }
  handleOpenSound() {
    this.player.openSound();
  }
  handleStopPlay() {
    this.player.stop();
  }
  handleStartPlay() {
    this.player.play({
      url: this.link,
    });
  }
  handleCapturePicture() {
    this.player.capturePicture(`${Date.now()}`);
  }
  handleStartSave() {
    this.player.startSave(`${Date.now()}`);
  }
  handleStopSave() {
    this.player.stopSave();
  }
}
</script>
<style lang="less" module>
.wrapVideo {
  position: relative;
  .ezPtzWrap {
    z-index: 999999;
    position: absolute;
    right: 20px;
    top: calc(50% - 50px);
    width: 100px;
    height: 100px;
    .ezPtzContainer {
      position: relative;
      width: 80px;
      height: 80px;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0 0 33px 4px rgb(0 0 0 / 15%);
      border: 1px solid rgba(255, 255, 255, 0.8);
      border-radius: 100%;
      cursor: pointer;
      overflow: hidden;
      user-select: none;
    }
    .ezPtzContainer0 {
      background-image: linear-gradient(
        180deg,
        rgb(29, 141, 216) 0%,
        rgba(100, 143, 252, 0) 30%
      );
    }
    .ezPtzContainer1 {
      background-image: linear-gradient(
        0deg,
        rgb(29, 141, 216) 0%,
        rgba(100, 143, 252, 0) 30%
      );
    }
    .ezPtzContainer2 {
      background-image: linear-gradient(
        90deg,
        rgb(29, 141, 216) 0%,
        rgba(100, 143, 252, 0) 30%
      );
    }
    .ezPtzContainer3 {
      background-image: linear-gradient(
        270deg,
        rgb(29, 141, 216) 0%,
        rgba(100, 143, 252, 0) 30%
      );
    }
    .ezPtzMain.center {
      width: 23px;
      height: 23px;
      background: #1890ff;
      border-radius: 100%;
      top: calc(50% - 12.3px);
      left: calc(50% - 12.3px);
      position: absolute;
    }
    .ezPtzIcon.top {
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-bottom: 6px solid #333;
      position: absolute;
      display: inline-block;
      left: calc(50% - 3px);
      top: 2px;
      border-bottom-color: #1890ff;
    }
    .ezPtzIcon.left {
      width: 0;
      height: 0;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-right: 6px solid #333;
      position: absolute;
      display: inline-block;
      top: calc(50% - 3px);
      left: 2px;
      border-right-color: #1890ff;
    }
    .ezPtzIcon.bottom {
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 6px solid #333;
      position: absolute;
      display: inline-block;
      left: calc(50% - 3px);
      bottom: 2px;
      border-top-color: #1890ff;
    }
    .ezPtzIcon.right {
      width: 0;
      height: 0;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-left: 6px solid #333;
      position: absolute;
      display: inline-block;
      top: calc(50% - 3px);
      right: 2px;
      border-left-color: #1890ff;
    }
  }
  &:hover {
    .wrapPlayer {
      display: flex;
    }
  }
  .wrapPlayer {
    display: none;
    justify-content: space-between;
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    right: 0;
    line-height: 0.3rem;
    padding: 0.1rem 2px;
    box-sizing: content-box;
    background-color: rgba(0, 0, 0, 0.2);

    .iconBtn {
      cursor: pointer;
      display: inline-flex;
    }
    .operation {
      height: 100%;
      display: inline-flex;
      align-items: center;
      padding: 0 0.1rem;
    }
  }
}
</style>
