import request from '../request';

const serviceName = '/smart-site/device/vs';
const backendServiceName = '/smart-site/backend/monitorModel';

// 获取直播地址
export function getLiveAddress(data) {
  return request(`${serviceName}/getLiveAddress`, {
    method: 'GET',
    body: data,
  });
}

// 获取通道号
export function getChannelNo(deviceId) {
  return request(`${serviceName}/getChannelNo/${deviceId}`, {
    method: 'GET',
  });
}

// 获取摄像头型号列表
export function getMonitorType() {
  return request(`${backendServiceName}/getMonitorType`, {
    method: 'GET',
  });
}
