var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"videoViewRef",class:_vm.$style.wrapVideo,attrs:{"id":"videoView"}},[_c('div',{attrs:{"id":_vm.id}}),(_vm.showPantile)?_c('div',{class:[_vm.$style.ezPtzWrap],staticStyle:{"display":"block"},attrs:{"id":"ez-ptz-item"}},[_c('div',{ref:"ptzContainer",class:[
        _vm.$style.ezPtzContainer,
        _vm.cameraDirection !== 4
          ? _vm.$style['ezPtzContainer' + _vm.cameraDirection]
          : '',
      ],attrs:{"id":"ez-ptz-container"},on:{"mousedown":e => {
          _vm.setDirection(e, 'start');
        },"mouseup":e => {
          _vm.setDirection(e, 'stop');
        }}},[_c('div',{class:[_vm.$style.ezPtzMain, _vm.$style.center]}),_c('div',{class:[_vm.$style.ezPtzIcon, _vm.$style.top, _vm.$style.active]}),_c('div',{class:[_vm.$style.ezPtzIcon, _vm.$style.left, _vm.$style.active]}),_c('div',{class:[_vm.$style.ezPtzIcon, _vm.$style.bottom, _vm.$style.active]}),_c('div',{class:[_vm.$style.ezPtzIcon, _vm.$style.right, _vm.$style.active]})])]):_vm._e(),(_vm.showOperation)?_c('div',{class:[_vm.$style.wrapPlayer]},[_c('span',{class:_vm.$style.operation},[_c('span',{class:_vm.$style.iconBtn,on:{"click":_vm.handleAutoAction}},[(_vm.autoAction)?_c('ali-icon',{style:(_vm.iconStyleObject),attrs:{"type":"tc-icon-zanting","title":"暂停"}}):_c('ali-icon',{style:(_vm.iconStyleObject),attrs:{"type":"tc-icon-bofang","title":"播放"}})],1),_c('span',{class:_vm.$style.iconBtn,on:{"click":_vm.handleSoundChange}},[(!_vm.sound)?_c('ali-icon',{style:(_vm.iconStyleObject),attrs:{"type":"tc-icon-jingyin","title":"静音"}}):_c('ali-icon',{style:(_vm.iconStyleObject),attrs:{"type":"tc-icon-jiayinliang","title":"声音"}})],1)]),_c('span',{class:_vm.$style.operation},[(_vm.isPantile)?_c('span',{class:_vm.$style.iconBtn,attrs:{"id":"pantile","title":"控制云台"},on:{"click":_vm.handlePantile}},[_c('ali-icon',{style:(_vm.iconStyleObject),attrs:{"type":"tc-icon-kongzhi"}})],1):_vm._e(),_c('span',{class:_vm.$style.iconBtn,on:{"click":_vm.handleActionHDORSD}},[(!_vm.boolIsSD)?_c('ali-icon',{style:(_vm.iconStyleObject),attrs:{"type":"tc-icon-gaoqing","title":"高清"}}):_c('ali-icon',{style:(_vm.iconStyleObject),attrs:{"type":"tc-icon-biaoqing1","title":"标清"}})],1),_c('span',{class:_vm.$style.iconBtn,on:{"click":_vm.handleCapturePicture}},[_c('ali-icon',{style:(_vm.iconStyleObject),attrs:{"type":"tc-icon-paizhao","title":"截图"}})],1),_c('span',{class:_vm.$style.iconBtn,on:{"click":_vm.handleActionSaveChange}},[_c('ali-icon',{style:({
            fontSize: _vm.iconSize,
            color: _vm.actionSave ? 'rgb(255, 0, 0)' : 'rgb(255, 255, 255)',
            padding: '0 10px',
          }),attrs:{"type":"tc-icon-a-shexiangjiluxiangji","title":"录屏"}})],1),(_vm.enableBack)?[(!_vm.isRecMode)?_c('span',{class:_vm.$style.iconBtn,on:{"click":_vm.handleModeChange}},[_c('ali-icon',{style:(_vm.iconStyleObject),attrs:{"type":"tc-icon-iconfonttubiao_shipinhuifang","title":"回放"}})],1):_c('span',{class:_vm.$style.iconBtn,on:{"click":_vm.handleModeChange}},[_c('ali-icon',{style:(_vm.iconStyleObject),attrs:{"type":"tc-icon-signal"}})],1)]:_vm._e(),_c('span',{class:_vm.$style.iconBtn,on:{"click":function($event){return _vm.handleFullScreen()}}},[_c('ali-icon',{style:(_vm.iconStyleObject),attrs:{"type":"tc-icon-a-quanping1","title":"全屏"}})],1)],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }